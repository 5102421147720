<template>
  <div class="noDataPageBox">
    <div v-if="type===1" class="imageBox">
      <img src="@/assets/image/nodata1.png" alt="nodata">
      <p>暂无图片</p>
    </div>
    <div v-else-if="type===2" class="imageBox">
      <img src="@/assets/image/nodata2.png" alt="nodata">
      <p>设置成</p>
    </div>
    <div v-else-if="type===3" class="imageBox">
      <img src="@/assets/image/nodata3.png" alt="nodata">
      <p>暂无数据</p>
    </div>
    <div v-else-if="type===4" class="imageBox">
      <img src="@/assets/image/nodata4.png" alt="nodata">
      <p>暂无网络</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: 1,
      type: Number
    }
  }
}
</script>

<style lang="scss">
.noDataPageBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    color: #333;
    img{
      width: 290px;
      height: 155px;
      margin-bottom: 20px;
    }
  }
}
</style>
